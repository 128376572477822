import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ContactHero from "../components/contact/contact-hero"
import ContactForm from "../components/contact/contact-form"

const ContactUsPage = () => (
  <Layout>
    <SEO title="Contact Bright and Clean Services" />
    <ContactHero/>
    <ContactForm/>
    
    
  </Layout>
)

export default ContactUsPage