import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
        <Container style={{paddingBottom: '3rem'}}>
            <Row style={{paddingTop: '2em'}}>
                <Col>
                    <h2>Contact Form</h2>
                    <hr className="service-offer-hr"/>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <form
        onSubmit={this.submitForm}
        action="https://formspree.io/f/meqploan"
        method="POST"
      >
          <div className="form-group">
       
        <input className="form-control" type="text" name="name" placeholder="Name"/>
        <input className="form-control" type="email" name="email" placeholder="Email Address"/>
        <input className="form-control" type="text" name="phone" placeholder="Phone Number"/>
        <textarea className="form-control" type="text" name="message" placeholder="Message" style={{height: '6rem'}}/>
        {status === "SUCCESS" ? <p>Thanks! We will be in touch with you shortly.</p> : <button className="btn btn-primary" id="contact-form-btn">Submit</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </div>
      </form>
                </Col>
            </Row>
     
      </Container>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}